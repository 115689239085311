import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [


//home
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Acceil.vue')
  },

  {
    path: '/login',
    name: 'login',
    // route level code-splitting AgriLaitAdd  
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Login/LoginPage.vue')

  },
//fin home


//agricult
{
  path: '/centres',
  name: 'centres',
  // route level code-splitting
  // this generates a separate chunk (agricult.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/CentreList.vue')
},
{
  path: '/agriculteurs',
  name: 'agriculteurs',
  // route level code-splitting
  // this generates a separate chunk (agricult.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/AgriList.vue')
},
{
  path: '/agriLaitAdd',
  name: 'agriLaitAdd',
  // route level code-splitting  
  // this generates a separate chunk (agricult.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/AgriLaitAdd.vue')
},
{
  path: '/agriLaitAddDetail',
  name: 'agriLaitAddDetail',
  // route level code-splitting  ServicesAgri
  // this generates a separate chunk (agricult.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/AgriLaitAddDetail.vue'),
  props: true 
},
{
  path: '/servicesAgri',
  name: 'servicesAgri',
  // route level code-splitting  
  // this generates a separate chunk (agricult.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/ServicesAgri/ServicesAgri.vue')
},
{
  path: '/servicesAgriDetail',
  name: 'servicesAgriDetail',
  // route level code-splitting  
  // this generates a separate chunk (agricult.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "agricult" */ '../views/AgriView/ServicesAgri/ServicesAgriDetail.vue'),
  props: true 
},
//fin agricult

//stock

{
  path: '/menuStock',
  name: 'menuStock',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/MenuStock.vue')
},
{
  path: '/menuEntrees',
  name: 'menuEntrees',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/MenuEntrees.vue')
},
{
  path: '/CategoriesList',
  name: 'CategoriesList',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/CategoriesList.vue')
},
{
  path: '/DepotsList',
  name: 'DepotsList',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/DepotListe.vue')
},
{
  path: '/ArticleList',
  name: 'ArticleList',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/ArticleListe.vue')
},

{
  path: '/menuDeplac',
  name: 'menuDeplac',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/MenuDeplac.vue')
},
{
  path: '/menuCmdDep',
  name: 'menuCmdDep',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/MenuCmdDep.vue')
},
{
  path: '/menuSorties',
  name: 'menuSorties',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/MenuSorties.vue')
},
  
{
  path: '/BonDeplac',
  name: 'BonDeplac',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnDeplacement/BonDeplac.vue'),
  props: true 
},
{
  path: '/BonDeplacDetail',
  name: 'BonDeplacDetail',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnDeplacement/BnDeplacDetails.vue'),
  props: true 
},
{
  path: '/listBnDeplac',
  name: 'listBnDeplac',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnDeplacement/ListBnDeplac.vue'),
  props: true 
},
{
  path: '/listBnDeplacValid',
  name: 'listBnDeplacValid',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnDeplacement/ListBnDeplacValid.vue'),
  props: true 
},
{
  path: '/bonEntre',
  name: 'bonEntre',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnEntre/BonEntre.vue'),
  props: true 
},
{
  path: '/bonEntreDetail',
  name: 'bonEntreDetail',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnEntre/BonEntreDetail.vue'),
  props: true 
},
{
  path: '/listBonEntre',
  name: 'listBonEntre',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnEntre/ListBonEntre.vue')
},
{
  path: '/listBonEntreVal',
  name: 'listBonEntreVal',
  // route level code-splitting ListBonEntreVal
  // this generates a separate chunk (stock.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnEntre/ListBonEntreVal.vue')
},
{
  path: '/bonSortie',
  name: 'bonSortie',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnSortie/BonSortie.vue'),
  props: true 
},
{
  path: '/bonSortieDetail',
  name: 'bonSortieDetail',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnSortie/BonSortieDetail.vue'),
  props: true 
},
{
  path: '/listBonSortie',
  name: 'listBonSortie',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnSortie/ListBonSortie.vue')
},
{
  path: '/listBonSortieVal',
  name: 'listBonSortieVal',
  // route level code-splitting
  // this generates a separate chunk (stock.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/BnSortie/ListBonSortieVal.vue')
},
{
  path: '/cmdDep',
  name: 'cmdDep',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/CmdDepot/CmdDep.vue')
},
{
  path: '/cmdDepDetail',
  name: 'cmdDepDetail',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/CmdDepot/CmdDepDetail.vue'),
  props: true 
},
{
  path: '/suiviCmdDep',
  name: 'suiviCmdDep',
  // route level code-splitting 
  // this generates a separate chunk (stock.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "stock" */ '../views/Stock/CmdDepot/SuiviCmdDep.vue'),
  props: true 
},

//fin stock 


//achat

{
  path: '/FournisseursList',
  name: 'FournisseursList',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/FournisseursList.vue')
},
{
  path: '/menuCmd',
  name: 'menuCmd',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/MenuCmd.vue')
},
{
  path: '/menuLivraison',
  name: 'menuLivraison',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/MenuLivraison.vue')
},
{
  path: '/menuAvoirFour',
  name: 'menuAvoirFour',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/MenuAvoirFour.vue')
},

{
  path: '/bnCmdFour',
  name: 'bnCmdFour',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Cmds/BnCmdFour.vue'),
  props: true 
},
{
  path: '/bnCmdFourDetail',
  name: 'bnCmdFourDetail',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Cmds/BnCmdFourDetail.vue'),
  props: true 
},
{
  path: '/listBnCmdFour',
  name: 'listBnCmdFour',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Cmds/ListBnCmdFour.vue') 
},
{
  path: '/listBnCmdArchiv',
  name: 'listBnCmdArchiv',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Cmds/ListBnCmdArchiv.vue') 
},
{
  path: '/bnLivraisonFour',
  name: 'bnLivraisonFour',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Livraisons/bnLivraisonFour.vue'),
  props: true 
},
{
  path: '/bnLivraisonFourDetail',
  name: 'bnLivraisonFourDetail',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Livraisons/BnLivraisonFourDetail.vue'),
  props: true 
},
{
  path: '/listBnLivraisonFour',
  name: 'listBnLivraisonFour',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Livraisons/ListBnLivraisonFour.vue') 
},
{
  path: '/listBnLivraisonVal',
  name: 'listBnLivraisonVal',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Livraisons/ListBnLivraisonVal.vue') 
},
{
  path: '/bnAvoir',
  name: 'bnAvoir',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Avoirs/BnAvoir.vue'),
  props: true 
},
{
  path: '/bnAvoirDetail',
  name: 'bnAvoirDetail',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Avoirs/BnAvoirDetail.vue'),
  props: true 
},
{
  path: '/listBnAvoirFour',
  name: 'listBnAvoirFour',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Avoirs/ListBnAvoirFour.vue') 
},
{
  path: '/listBnAvoirFourVal',
  name: 'listBnAvoirFourVal',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "achat" */ '../views/Achats/Avoirs/ListBnAvoirFourVal.vue') 
},

//fin Achat

//finance

{
  path: '/banques',
  name: 'banques',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Config/BanqList.vue')
},
{
  path: '/menuTresorerie',
  name: 'menuTresorerie',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/ChargDecharg/MenuTresorerie.vue')
},
{
  path: '/bankCompte',
  name: 'bankCompte',
  // route level code-splitting BankMode
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/BankCompte.vue')
},
{
  path: '/bankMode',
  name: 'bankMode',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/BankMode.vue')
},
{
  path: '/bankOperations',
  name: 'bankOperations',
  // route level code-splitting BankOperations
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/BankOperations.vue')
},
{
  path: '/depenseList',
  name: 'depenseList',
  // route level code-splitting BankOperations
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/DepenseList.vue')
},
{
  path: '/paiementFour',
  name: 'paiementFour',
  // route level code-splitting BankOperations 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/PaiementFour.vue')
},
{
  path: '/paiementGarage',
  name: 'paiementGarage',
  // route level code-splitting BankOperations 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/PaiementGarage.vue')
},
{
  path: '/paiemFourRetard',
  name: 'paiemFourRetard',
  // route level code-splitting BankOperations 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "finance" */ '../views/Tresor/PaiemFourRetard.vue')
},
//fin finance



//chareg-decharge
{
  path: '/menuClientVend',
  name: 'menuClientVend',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/MenuClientVend.vue')
},
{
  path: '/menuPrimeClient',
  name: 'menuPrimeClient',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/MenuPrimeClient.vue')
},
{
  path: '/menuCamion',
  name: 'menuCamion',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/MenuCamion.vue')
},
{
  path: '/menuCharg',
  name: 'menuCharg',
  // route level code-splitting BnCmdFour
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/MenuCharg.vue')
},

{
  path: '/ZonesList',
  name: 'ZonesList',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/ZoneList.vue')
},
{
  path: '/clientsList',
  name: 'clientsList',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/ClientsList.vue')
},
{
  path: '/suiviClients',
  name: 'suiviClients',
  // route level code-splitting SuiviClients
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/SuiviClients.vue')
},
{
  path: '/qrCodeClients',
  name: 'qrCodeClients',
  // route level code-splitting SuiviClients
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/QrCodeClients.vue')
},
{
  path: '/vendeursList',
  name: 'vendeursList',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/VendeursList.vue')
},
{
  path: '/suiviVendeurs',
  name: 'suiviVendeurs',
  // route level code-splitting SuiviClients
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/SuiviVendeurs.vue')
},
{
  path: '/listCamion',
  name: 'listCamion',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/Camions/ListCamion.vue')
},
{
  path: '/maitenances',
  name: 'maitenances',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/Camions/Maitenances.vue')
},
{
  path: '/listMaintCamion',
  name: 'listMaintCamion',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/Camions/ListMaintCamion.vue'),
  props: true 
},
{
  path: '/listKilomCamion',
  name: 'listKilomCamion',
  // route level code-splitting KilomCamRapp
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/Camions/ListKilomCamion.vue')
},
{
  path: '/bnPrimClient',
  name: 'bnPrimClient',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/PrimClient/BnPrimClient.vue'),
  props: true 
},
{
  path: '/bnPrimClientDetail',
  name: 'bnPrimClientDetail',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/PrimClient/BnPrimClientDetail.vue'),
  props: true 
},
{
  path: '/listBnPrimClient',
  name: 'listBnPrimClient',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/PrimClient/ListBnPrimClient.vue')
},
{
  path: '/listBnPrimClientVal',
  name: 'listBnPrimClientVal',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/PrimClient/ListBnPrimClientVal.vue')
},
{
  path: '/bonCharge',
  name: 'bonCharge',
  // route level code-splitting BonDecharge
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/BonCharge.vue'),
  props: true 
},
{
  path: '/bonChargeDetail',
  name: 'bonChargeDetail',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/BonChargeDetail.vue'),
  props: true 
},
{
  path: '/listBonCharge',
  name: 'listBonCharge',
  // route level code-splitting ListBonChargeVal
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/ListBonCharge.vue')
},
{
  path: '/listBonChargeVal',
  name: 'listBonChargeVal',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/ListBonChargeVal.vue')
},
{
  path: '/listBonChargeReg',
  name: 'listBonChargeReg',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route 
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/ListBonChargeReg.vue')
},
{
  path: '/bonDecharge',
  name: 'bonDecharge',
  // route level code-splitting 
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/BonDecharge.vue'),
  props: true 
},
{
  path: '/boites',
  name: 'boites',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/Config/BoitesList.vue')
},
{
  path: '/bnChargeVentes',
  name: 'bnChargeVentes',
  // route level code-splitting  
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/BnCharge/bnChargeVentes.vue'),
  props: true 
},
{
  path: '/trajetVend',
  name: 'trajetVend',
  // route level code-splitting  
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/TrajetVend.vue'),
  props: true 
},
{
  path: '/stockBoites',
  name: 'stockBoites',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/StockBoites.vue')
},
{
  path: '/stockBoiteCasse',
  name: 'stockBoiteCasse',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/StockBoiteCasse.vue'),
  props: true 
},
{
  path: '/stockCaisseDeplac',
  name: 'stockCaisseDeplac',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "charg" */ '../views/ChargDecharg/StockCaisseDeplac.vue'),
  props: true 
},
//fin chareg-decharge 





//rapport
  {
    path: '/ListLaitCollected',
    name: 'ListLaitCollected',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListLaitCollected.vue')
  },
  {
    path: '/listimpagriRapp',
    name: 'listimpagriRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListImpayesAgriRapp.vue')
  },
  {
    path: '/paiemAgriRapp',
    name: 'paiemAgriRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListpaiemAgriRapp.vue')
  },
  {
    path: '/agriBankListVal',
    name: 'agriBankListVal',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/AgriBankListVal.vue')
  },
  {
    path: '/agriOrderPaie',
    name: 'agriOrderPaie',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/AgriOrderPaie.vue')
  },
  {
    path: '/listServicesRapp',
    name: 'listServicesRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListServicesRapp.vue')
  },

  {
    path: '/listServiceParAgriRapp',
    name: 'listServiceParAgriRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListServiceParAgriRapp.vue')
  },

  {
    path: '/agriRapMenu',
    name: 'agriRapMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/AgriRapMenu.vue')
  },
  {
    path: '/stockRappMenu',
    name: 'stockRappMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/StockRappMenu.vue')
  },

  {
    path: '/achatRappMenu',
    name: 'achatRappMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/AchatRappMenu.vue')
  },

  {
    path: '/ventesRetourMenu',
    name: 'ventesRetourMenu',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/VentesRetourMenu.vue')
  },

  {
    path: '/camionBoiteRapMenu',
    name: 'camionBoiteRapMenu',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/CamionBoiteRapMenu.vue')
  },

  {
    path: '/financeRappMenu',
    name: 'financeRappMenu',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/FinanceRappMenu.vue')
  },
  {
    path: '/rhRappMenu',
    name: 'rhRappMenu',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/RhRappMenu.vue')
  },
  {
    path: '/liststockLotsRapp',
    name: 'liststockLotsRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/ListstockLotsRapp.vue')
  },
  {
    path: '/listExpirRapp',
    name: 'listExpirRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/ListExpirRapp.vue')
  },
  {
    path: '/inventaireRapp',
    name: 'inventaireRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/InventaireRapp.vue')
  },
  {
    path: '/listEntreesRapp',
    name: 'listEntreesRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/ListEntreesRapp.vue')
  },
  {
    path: '/listSortiesRapp',
    name: 'listSortiesRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/ListSortiesRapp.vue')
  },
  {
    path: '/listDeplacRapp',
    name: 'listDeplacRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Stock/ListDeplacRapp.vue')
  },

  {
    path: '/listArticlAchatsRapp',
    name: 'listArticlAchatsRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Achats/ListArticlAchatsRapp.vue') 
  },
  {
    path: '/listBonAchatsRapp',
    name: 'listBonAchatsRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Achats/ListBonAchatsRapp.vue') 
  },
 

  {
    path: '/kilomCamRapp',
    name: 'kilomCamRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/Camions/KilomCamRapp.vue')
  },
  {
    path: '/boitesRapp',
    name: 'boitesRapp',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/BoitesRapp.vue')
  },

  {
    path: '/ventQteRapp',
    name: 'ventQteRapp',
    // route level code-splitting  
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/VentQteRapp.vue'),
    props: true 
  },
  {
    path: '/ventTotalRapp',
    name: 'ventTotalRapp',
    // route level code-splitting  
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/VentTotalRapp.vue'),
    props: true 
  },
  {
    path: '/ventChiffPrClient',
    name: 'ventChiffPrClient',
    // route level code-splitting  
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/VentChiffPrClient.vue'),
    props: true 
  },
  {
    path: '/paiemClitVendRetr',
    name: 'paiemClitVendRetr',
    // route level code-splitting  
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Tresor/PaiemClitVendRetr.vue')
  },
  
  {
    path: '/listSoldesCompteRapp',
    name: 'listSoldesCompteRapp',
    // route level code-splitting  
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Tresor/ListSoldesCompteRapp.vue')
  },
  {
    path: '/parametresMenu',
    name: 'parametresMenu',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/ParametresMenu.vue')
  },
  {
    path: '/usersListe',
    name: 'usersListe',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Login/UsersListe.vue')

  },
  {
    path: '/menuRappPos',
    name: 'menuRappPos',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/POS/MenuRappPos.vue')

  },
  {
    path: '/rappVentePos',
    name: 'rappVentePos',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/POS/RappVentePos.vue')

  },
  {
    path: '/rappQtePos',
    name: 'rappQtePos',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/POS/RappQtePos.vue')

  },
  {
    path: '/salairesListRapp',
    name: 'salairesListRapp',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RH/SalairesListRapp.vue')

  },
  {
    path: '/absencesRapp',
    name: 'absencesRapp',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RH/AbsencesRapp.vue')

  },
  {
    path: '/congesRapp',
    name: 'congesRapp',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RH/CongesRapp.vue')

  },
  {
    path: '/stockCaisseDeplacRapp',
    name: 'stockCaisseDeplacRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/StockCaisseDeplacRapp.vue')
  },
  {
    path: '/caisseCassesRapp',
    name: 'caisseCassesRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/ChargDecharg/CaisseCassesRapp.vue')
  },
  
  {
    path: '/pointageRapp',
    name: 'pointageRapp',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RH/PointageRapp.vue')

  },
  //rapports fin annees:
  {
    path: '/listLaitCollParCentreRapp',
    name: 'listLaitCollParCentreRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListLaitCollParCentreRapp.vue')
  },
  {
    path: '/listAgriAvecCreditsRapp',
    name: 'listAgriAvecCreditsRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListAgriAvecCreditsRapp.vue')
  },
  {
    path: '/listAvancesAgrisListRapp',
    name: 'listAvancesAgrisListRapp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListAvancesAgrisListRapp.vue')
  },
  {
    path: '/listResumePaiemAgris',
    name: 'listResumePaiemAgris',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/AgriView/ListResumePaiemAgris.vue')
  },

  
  {
    path: '/resumePaiemFournisRapp',
    name: 'resumePaiemFournisRapp',
    // route level code-splitting BankOperations 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/Tresor/ResumePaiemFournisRapp.vue')
  },
  {
    path: '/rappFinAnnesMenu',
    name: 'rappFinAnnesMenu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/RappFinAnnesMenu.vue')
  },
  
  //========================:DEUXIEME TRANCHE :=============================
  {
    path: '/posCaisse',
    name: 'posCaisse',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/PosCaisse.vue')

  },
  {
    path: '/posCategrArticle',
    name: 'posCategrArticle',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/PosCategrArticle.vue')

  },
  {
    path: '/posPage',
    name: 'posPage',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/PosPage.vue'),
    props: true 

  },
  {
    path: '/posMenu',
    name: 'posMenu',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/PosMenu.vue')

  },
  {
    path: '/listeCMDs',
    name: 'listeCMDs',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/ListeCMDs.vue'),
    props: true 

  },
  {
    path: '/agriServicePOS',
    name: 'agriServicePOS',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pos" */ '../views/POS/AgriServicePOS.vue'),
    props: true 

  },
//RH 
  {
    path: '/departementsList',
    name: 'departementsList',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/DepartementsList.vue'),
    props: true 

  },
  {
    path: '/employesList',
    name: 'employesList',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/EmployesList.vue'),
    props: true 

  },
  {
    path: '/absencesList',
    name: 'absencesList',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/AbsencesList.vue'),
    props: true 

  },
  {
    path: '/congesList',
    name: 'congesList',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/CongesList.vue'),
    props: true 

  },
  {
    path: '/feuilleTemps',
    name: 'feuilleTemps',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/FeuilleTemps.vue'),
    props: true 

  },
  {
    path: '/traitementSalaire',
    name: 'traitementSalaire',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rh" */ '../views/RH/TraitementSalaire.vue'),
    props: true 

  },
  {
    path: '/prodEntrees',
    name: 'prodEntrees',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/ProdEntrees.vue'),
    props: true 

  },
  {
    path: '/prodSorties',
    name: 'prodSorties',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/ProdSorties.vue'),
    props: true 

  },

  {
    path: '/prodProcesList',
    name: 'prodProcesList',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Proc/ProdProcesList.vue'),
    props: true 

  },
  {
    path: '/prodProcDetail',
    name: 'prodProcDetail',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Proc/ProdProcDetail.vue'),
    props: true 

  },
  {
    path: '/productionAcceil',
    name: 'productionAcceil',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ProductionAcceil.vue'),
    props: true 

  },
  {
    path: '/listProductions',
    name: 'listProductions',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ListProductions.vue'),
    props: true 

  },
  {
    path: '/productionEntreesProd',
    name: 'productionEntreesProd',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ProductionEntreesProd.vue'),
    props: true 

  },
  {
    path: '/productionSortiesProd',
    name: 'productionSortiesProd',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ProductionSortiesProd.vue'),
    props: true 

  },
  {
    path: '/rappProdProcess',
    name: 'rappProdProcess',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/RappProdProcess.vue'),
    props: true 

  },
  {
    path: '/menuProcessus',
    name: 'menuProcessus',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/menuProcessus.vue'),
    props: true 

  },
  {
    path: '/menuProduction',
    name: 'menuProduction',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/menuProduction.vue'),
    props: true 

  },
  {
    path: '/listProductionsEncours',
    name: 'listProductionsEncours',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ListProductionsEncours.vue'),
    props: true 

  },
  {
    path: '/listProductionsProd',
    name: 'listProductionsProd',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ListProductionsProd.vue'),
    props: true 

  },
  {
    path: '/listProductionArchiver',
    name: 'listProductionArchiver',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Production/ListProductionArchiver.vue'),
    props: true 

  },
  {
    path: '/rappProdProcResum',
    name: 'rappProdProcResum',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/RappProdProcResum.vue'),
    props: true 

  },
  {
    path: '/rappProdProduitFini',
    name: 'rappProdProduitFini',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/RappProdProduitFini.vue'),
    props: true 

  },
  {
    path: '/rappProdSorties',
    name: 'rappProdSorties',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/RappProdSorties.vue'),
    props: true 

  },
  {
    path: '/prodReception',
    name: 'prodReception',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Recep/ProdReception.vue'),
    props: true 

  },
  {
    path: '/prodReceptionDetails',
    name: 'prodReceptionDetails',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Recep/ProdReceptionDetails.vue'),
    props: true 

  },

  {
    path: '/menuReception',
    name: 'menuReception',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/menuReception.vue'),
    props: true 

  },
  {
    path: '/listReception',
    name: 'listReception',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Recep/ListReception.vue'),
    props: true 

  },
  
  {
    path: '/listReceptionVal',
    name: 'listReceptionVal',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Recep/ListReceptionVal.vue'),
    props: true 

  },

  
  {
    path: '/listReceptionSortVal',
    name: 'listReceptionSortVal',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/Recep/ListReceptionSortVal.vue'),
    props: true 

  },
  {
    path: '/rappProdReception',
    name: 'rappProdReception',
    // route level code-splitting   
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "prod" */ '../views/Prod/RappProdReception.vue'),
    props: true 

  },

  {
    path: '/rappStatProduction',
    name: 'rappStatProduction',
    // route level code-splitting 
    // this generates a separate chunk (about.[hash].js) for this route 
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rapport" */ '../views/RappStat/RappStatProduction.vue')
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
