import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'


axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LotsListX:[],
    DroitsListX:[],
    loggedIn: false,
    user: null,
    token: null,

    //droits agriculteurs:
    gst_cent: false,
    gst_agri: false,
    gst_col_lait: false,
    gst_suiv_agri: false,
    //droits stock:
    gst_depo: false,
    gst_cat: false,
    gst_art: false,

    gst_deplac: false,
    gst_deplac_val: false,
    gst_entre: false,
    gst_entre_val: false,
    gst_sorti: false,
    gst_sorti_val: false,
    gst_cmd_dep: false,
    gst_suivi_cmd_dep: false,



    //droits achats
    gst_fournis:false,
    gst_cmd: false,
    gst_livrais: false,
    gst_livrais_val: false,
    gst_avoir: false,
    gst_avoir_val: false,

    //droits tresor
    gst_bnq: false,
    gst_tresor: false,
    gst_depens: false,
    gst_paiem_four: false,

        //droits charge
        gst_client_zon: false,
        gst_vendr: false,
        gst_zone_qrcode: false,
        gst_suivi_client_vendr: false,
        gst_chiffr_aff: false,

        gst_prim: false,
        gst_cam_boit: false,
        gst_boit: false,
        gst_charg: false,
        gst_charg_val: false,
        gst_charg_vent: false,
        gst_trajet_vend: false,

        
                //droits parties
                par_agri: true,
                par_stock: true,
                par_achat: true,
                par_financ: true,
                par_charg: true,
                par_rapp: true,
                par_param: true,
                par_pos: true,
                par_rh: true,
                par_prod: true,
                rapp_fin_an: false,

                //droits POS
                gst_caisse_pos: false,
                gst_art_cat_pos: false,
                gst_point_vent: false,
                gst_paie_pos: false,

                //droits RH
                gst_depart_employ: false,
                gst_absencerh: false,
                gst_congesrh: false,
                gst_feuill_temp: false,
                gst_trait_salaire: false,

                
                //droits prod
                gst_prod_proces: false,
                gst_prod_recep: false,
                gst_prod_prod: false,
                gst_prod_stock: false,

  },
  mutations: {
    SET_LotsListX(state, payload) {
      state.LotsListX = payload;
    },
    Add_LotToListX(state, lot) {
      state.LotsListX.unshift(lot) ;
    },
    DELETE_LotToListX(state, lot) {
      let index = state.LotsListX.findIndex(item => item.id === lot.id)
      state.LotsListX.splice(index, 1)
  },



  SET_user(state, payload) {
    state.user = payload;
  },
  SET_token(state, payload) {
    state.token = payload;
  },
  SET_loggedIn(state, payload) {
    state.loggedIn = payload;
  },
  SET_Droits(state, payload) {
    state.DroitsListX = payload;


  }
  },
  actions: {
    addLotsToListX({ commit },lot) {

      commit("Add_LotToListX",  lot);

    },
    deleteLotsToListX({ commit },lot) {

      commit("DELETE_LotToListX",  lot);

    },
    loadLotsListX({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/lots')
          .then(res => {
            commit("SET_LotsListX",  res.data.data);
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
  },
  loadDroitListX({ commit,state }, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/droitsUser/'+payload.user_id)
        .then(res => {
          commit("SET_Droits",  res.data.data);

          //desactive prod:
          state.par_prod=false



          res.data.data.forEach(function (arrayItem) {

            if("gst_cent"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_cent=true
            }
            else if("gst_agri"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_agri=true
            }
            else if("gst_col_lait"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_col_lait=true
            }
            else if("gst_suiv_agri"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_suiv_agri=true
            }
            //stock
            else if("gst_depo"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_depo=true
            }
            else if("gst_cat"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_cat=true
            }
            else if("gst_art"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_art=true
            }
            else if("gst_deplac"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_deplac=true
            }
            else if("gst_deplac_val"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_deplac_val=true
            }
            else if("gst_entre"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_entre=true
            }
            else if("gst_entre_val"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_entre_val=true
            }
            else if("gst_sorti"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_sorti=true
            }
            else if("gst_sorti_val"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_sorti_val=true
            }
            else if("gst_cmd_dep"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_cmd_dep=true
            }
            else if("gst_suivi_cmd_dep"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_suivi_cmd_dep=true
            }

            //achats: 
            else if("gst_fournis"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_fournis=true
            }
            else if("gst_cmd"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_cmd=true
            }
            else if("gst_livrais"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_livrais=true
            }
            else if("gst_livrais_val"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_livrais_val=true
            }
            else if("gst_avoir"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_avoir=true
            }
            else if("gst_avoir_val"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_avoir_val=true
            }
            //tresor:
            else if("gst_bnq"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_bnq=true
            }
            else if("gst_tresor"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_tresor=true
            }
            else if("gst_depens"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_depens=true
            }
            else if("gst_paiem_four"==arrayItem.nom && arrayItem.access==1)
            {
              state.gst_paiem_four=true
            }
            //charge decharge:


        else if("gst_client_zon"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_client_zon=true
                      }
        else if("gst_vendr"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_vendr=true
                      }

        else if("gst_zone_qrcode"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_zone_qrcode=true
                      }
        else if("gst_suivi_client_vendr"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_suivi_client_vendr=true
                      }
        else if("gst_chiffr_aff"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_chiffr_aff=true
                      }
        else if("gst_prim"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_prim=true
                      }
        else if("gst_cam_boit"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_cam_boit=true
                      }
        else if("gst_boit"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_boit=true
                      }
        else if("gst_charg"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_charg=true
                      }
        else if("gst_charg_val"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_charg_val=true
                      }
        else if("gst_charg_vent"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_charg_vent=true
                      }
        else if("gst_trajet_vend"==arrayItem.nom && arrayItem.access==1)
                      {
                        state.gst_trajet_vend=true
                      }
                    //POS:
                    else if("gst_caisse_pos"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_caisse_pos=true
                    }
                    else if("gst_art_cat_pos"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_art_cat_pos=true
                    }
                    else if("gst_point_vent"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_point_vent=true
                    }
                    else if("gst_paie_pos"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_paie_pos=true
                    }

                    //POS:
                    else if("gst_depart_employ"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_depart_employ=true
                    }
                    else if("gst_absencerh"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_absencerh=true
                    }
                    else if("gst_congesrh"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_congesrh=true
                    }
                    else if("gst_feuill_temp"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_feuill_temp=true
                    }
                    else if("gst_trait_salaire"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_trait_salaire=true
                    }
               
                    //Prod:
                    else if("gst_prod_proces"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_prod_proces=true
                    }
                    else if("gst_prod_recep"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_prod_recep=true
                    }
                    else if("gst_prod_prod"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_prod_prod=true
                    }
                    else if("gst_prod_stock"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.gst_prod_stock=true
                    }
                    //rapports fin années:
                    
                    else if("rapp_fin_an"==arrayItem.nom && arrayItem.access==1)
                    {
                      state.rapp_fin_an=true
                    }
        //doits parties: 
          else if("par_agri"==arrayItem.nom)
                  {
                          if(arrayItem.access==1)
                          {
                            state.par_agri=true
                          }
                          else
                          {
                            state.par_agri=false
                          }

                    
                  }
          else if("par_stock"==arrayItem.nom )
                  {

                    if(arrayItem.access==1)
                    {
                      state.par_stock=true
                    }
                    else
                    {
                      state.par_stock=false
                    }



                  }
          else if("par_achat"==arrayItem.nom )
                  {

                    if(arrayItem.access==1)
                    {
                      state.par_achat=true
                    }
                    else
                    {
                      state.par_achat=false
                    }
                  }
          else if("par_financ"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_financ=true
                    }
                    else
                    {
                      state.par_financ=false
                    }

                  }
          else if("par_charg"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_charg=true
                    }
                    else
                    {
                      state.par_charg=false
                    }

                  }
          else if("par_rapp"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_rapp=true
                    }
                    else
                    {
                      state.par_rapp=false
                    }
                    
          
                  }
          else if("par_param"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_param=true
                    }
                    else
                    {
                      state.par_param=false
                    }

                  }
           else if("par_pos"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_pos=true
                    }
                    else
                    {
                      state.par_pos=false
                    }

                  }
           else if("par_rh"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_rh=true
                    }
                    else
                    {
                      state.par_rh=false
                    }

                  }
           else if("par_prod"==arrayItem.nom )
                  {
                    if(arrayItem.access==1)
                    {
                      state.par_prod=true
                    }
                    else
                    {
                      state.par_prod=false
                    }

                  }
      //charge Partie:
        });








          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
},
  performLoginAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", {
          email: payload.email,
          password: payload.password
        })
        .then(res => {
          commit("SET_token", res.data.access_token);
          commit("SET_user", res.data.user);
          commit("SET_Droits", res.data.droits);
          commit("SET_loggedIn", true);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  performLogoutAction({ commit, state }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/auth/logout", {
          token: state.token
        })

        .then(res => {
          commit("SET_token", null);

          commit("SET_loggedIn", false);
          commit("SET_user", null);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },




},
getters: {
  LotsListX: state => {
    return state.LotsListX
},
get_loggedIn(state) {
  return state.loggedIn;
},
get_user(state) {
  return state.user;
},
get_token(state) {
  return state.token;
},
//les droits: 
get_gst_cent(state) {
  return state.gst_cent;
},
get_gst_agri(state) {
  return state.gst_agri;
},
get_gst_col_lait(state) {
  return state.gst_col_lait;
},
get_gst_suiv_agri(state) {
  return state.gst_suiv_agri;
},
//stock
get_gst_depo(state) {
  return state.gst_depo;
},
get_gst_cat(state) {
  return state.gst_cat;
},
get_gst_art(state) {
  return state.gst_art;
},
get_gst_deplac(state) {
  return state.gst_deplac;
},
get_gst_deplac_val(state) {
  return state.gst_deplac_val;
},
get_gst_entre(state) {
  return state.gst_entre;
},
get_gst_entre_val(state) {
  return state.gst_entre_val;
},
get_gst_sorti(state) {
  return state.gst_sorti;
},
get_gst_sorti_val(state) {
  return state.gst_sorti_val;
},
get_gst_cmd_dep(state) {
  return state.gst_cmd_dep;
},
get_gst_suivi_cmd_dep(state) {
  return state.gst_suivi_cmd_dep;
},

//achat
get_gst_fournis(state) {
  return state.gst_fournis;
},
get_gst_cmd(state) {
  return state.gst_cmd;
},
get_gst_livrais(state) {
  return state.gst_livrais;
},
get_gst_livrais_val(state) {
  return state.gst_livrais_val;
},
get_gst_avoir(state) {
  return state.gst_avoir;
},
get_gst_avoir_val(state) {
  return state.gst_avoir_val;
},
//tresor
get_gst_bnq(state) {
  return state.gst_bnq;
},
get_gst_tresor(state) {
  return state.gst_tresor;
},
get_gst_depens(state) {
  return state.gst_depens;
},
get_gst_paiem_four(state) {
  return state.gst_paiem_four;
},
//charg /decharge

get_gst_client_zon(state) {
  return state.gst_client_zon;
},
get_gst_vendr(state) {
  return state.gst_vendr;
},

get_gst_zone_qrcode(state) {
  return state.gst_zone_qrcode;
},
get_gst_suivi_client_vendr(state) {
  return state.gst_suivi_client_vendr;
},
get_gst_chiffr_aff(state) {
  return state.gst_chiffr_aff;
},

get_gst_prim(state) {
  return state.gst_prim;
},
get_gst_cam_boit(state) {
  return state.gst_cam_boit;
},
get_gst_boit(state) {
  return state.gst_boit;
},
get_gst_charg(state) {
  return state.gst_charg;
},
get_gst_charg_val(state) {
  return state.gst_charg_val;
},
get_gst_charg_vent(state) {
  return state.gst_charg_vent;
},
get_gst_trajet_vend(state) {
  return state.gst_trajet_vend;
},
//POS
get_gst_caisse_pos(state) {
  return state.gst_caisse_pos;
},
get_gst_art_cat_pos(state) {
  return state.gst_art_cat_pos;
},
get_gst_point_vent(state) {
  return state.gst_point_vent;
},
get_gst_paie_pos(state) {
  return state.gst_paie_pos;
},
//RH
get_gst_depart_employ(state) {
  return state.gst_depart_employ;
},
get_gst_absencerh(state) {
  return state.gst_absencerh;
},
get_gst_congesrh(state) {
  return state.gst_congesrh;
},
get_gst_feuill_temp(state) {
  return state.gst_feuill_temp;
},
get_gst_trait_salaire(state) {
  return state.gst_trait_salaire;
},
//prod
get_gst_prod_proces(state) {
  return state.gst_prod_proces;
},
get_gst_prod_recep(state) {
  return state.gst_prod_recep;
},
get_gst_prod_prod(state) {
  return state.gst_prod_prod;
},
get_gst_prod_stock(state) {
  return state.gst_prod_stock;
},

get_rapp_fin_an(state) {
  return state.rapp_fin_an;
},




//Parties
get_par_agri(state) {
  return state.par_agri;
},
get_par_stock(state) {
  return state.par_stock;
},
get_par_achat(state) {
  return state.par_achat;
},
get_par_financ(state) {
  return state.par_financ;
},
get_par_charg(state) {
  return state.par_charg;
},
get_par_rapp(state) {
  return state.par_rapp;
},
get_par_param(state) {
  return state.par_param;
},
get_par_pos(state) {
  return state.par_pos;
},
get_par_rh(state) {
  return state.par_rh;
},
get_par_prod(state) {
  return state.par_prod;
},

}
,
  modules: {
  }
})
